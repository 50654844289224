import './contact.css';
import React from 'react';


export function Contact(){
return(
    <div className='contact '>

    <div className=' contactContainer col-5'> Dzięki programowi teamVier wszytkie  </div>
    
    </div>
)
}