import './mainpage.css';
import React from 'react';
import MainLogo from './sgpulogo.jpg'
import {FirstSection}from './firstSection.jsx'


export function Mainpage() {


    return (
        <div className='mainpage'>
            <div className='navbar fixed-top mx-auto'>
                <div className="MainIcon">
                <img src={MainLogo} className="MainLogo" alt="Girl in a jacket" ></img>

                </div>
                <div className='containerLinks  col-xxl-5 col-xl-6 row ms-auto ps-5 mt-3'>
<div className='mainLink col-4 main'>
Strona główna
</div>   
<div className='mainLink  col-4 '>
Nasze usługi</div> 
<div className='mainLink   col-3 '>
Kontakt</div> 
             </div>
            </div>
<FirstSection/>


        </div>
        
    )
}