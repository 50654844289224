import './firstSection.css';
import React from 'react';
import gpuHand from './gpuhand.jpg'

export function FirstSection() {

    return(
        <div className='col-12 mx-auto  row firstSection '>
<div className='infoBanner bounceInLeft  col-xl-5  ms-5 col-xxl-5 '>

Chyba każdy po pewnym czasie nie jest zadowolony z komputera i chciałby poprawić jego wydajność. A to wyszła nowa gra i nie chodzi płynnie. Albo film mógłby się krócej renderować. Niestety, każdy PeCet po jakimś czasie zaczyna okazywać się niewystarczający. 
<div className='mt-5 Important'>Jeśli również Tobie doskiwiera niższa wydajność komputera możemy Ci pomóc!</div>

</div>
<div className='col-xxl-2  col-xl-1 '>
</div>
<div className='col-xxl-4 col-xl-5  '>
    <img src={gpuHand} className='gpuHandimg        bounceInRight  ' alt='gpu hand'></img>

        </div>
</div>
    )
}