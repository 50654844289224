import React from 'react';
import { Mainpage } from './mainpage';
import {OurServices} from "./ourServices"
import {Contact}from './contact.jsx'

function App() {
  return (

<>
  <Mainpage/>
  <OurServices/>
  <Contact/>
</> 
 );
}

export default App;




